import type { TranslationObj } from "$/utils/lang"

import type { TEmptyCallback } from "@/shared/types/functions"

enum EMode {
    View = "view",
    Edit = "edit"
}

type TProps = { content?: TranslationObj | string; mode?: EMode; onChange?(data: string): void | TEmptyCallback }

export { EMode as ERichTextRendererMode, type TProps as TRichTextRendererProps }
