// eslint-disable-next-line import/no-extraneous-dependencies
import type { User as DatadogUser } from "@datadog/browser-core"
import { datadogLogs } from "@datadog/browser-logs"
import { datadogRum } from "@datadog/browser-rum"

import type { TUserModel } from "@/adapters"
import { isParticipant } from "@/utils/gates/user"

const setUserDataDog: (user: TUserModel) => void = (user: TUserModel): void => {
    const userData: DatadogUser = {
        id: String(user.userId),
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        cohort: isParticipant(user) ? user.cohort : user.role,
        organization: user.organization,
        version: JSAPP_CONF?.static ?? ""
    }

    datadogRum.setUser(userData)

    datadogLogs.setUser(userData)
}

export { setUserDataDog }
