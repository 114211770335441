import { isObject } from "$/utils/gates"
import type { TranslationObj } from "$/utils/lang"

import type { TRichTextRendererProps } from "./index"

function propsChecker(
    { content: prevContent }: TRichTextRendererProps,
    { content: nextContent }: TRichTextRendererProps
): boolean {
    if (!prevContent) return false

    return isObject(nextContent)
        ? (prevContent as TranslationObj)?.en?.length === (nextContent as TranslationObj)?.en?.length
        : (prevContent as string)?.length === nextContent?.length
}

const domParser: DOMParser = new DOMParser()

function isStringHTML(content: string): boolean {
    try {
        const parsedDocument: Document = domParser.parseFromString(content, "text/html")

        return parsedDocument.body.childElementCount > 0 && parsedDocument.querySelector("parsererror") === null
    } catch (_error) {
        return false
    }
}

function isStringMarkdown(content: string): boolean {
    const htmlTagPattern: RegExp = /<\/?[a-z][\s\S]*>/i

    if (htmlTagPattern.test(content)) {
        return false
    }

    const markdownPattern: RegExp = /(\*\*|__|\*|_|~~|#|-|\+|\d+\.)/

    return markdownPattern.test(content)
}

function isStringPlainText(content: string): boolean {
    const htmlPattern: RegExp = /<\/?[a-z][\s\S]*>/i
    const markdownPattern: RegExp = /(^\s{0,3}#{1,6}\s|[*_`~>]|!\[.*]\(.*\)|\[(.*?)]\((.*?)\)|\s{0,3}[-*+]\s|\d+\.\s)/m

    return !htmlPattern.test(content) && !markdownPattern.test(content)
}

type TUtils = {
    propsChecker(prevProps: TRichTextRendererProps, nextProps: TRichTextRendererProps): boolean
    isStringHTML(content: string): boolean
    isStringMarkdown(content: string): boolean
    isStringPlainText(content: string): boolean
}

const richTextRendererUtils: TUtils = { propsChecker, isStringHTML, isStringMarkdown, isStringPlainText }

export { richTextRendererUtils, type TUtils as TRichRendererUtils }
