import { useLayoutEffect } from "react"

import { type TEmptyCallback, emptyCallback } from "@/shared/types/functions"

const STYLESHEET_LINK_HREF: string = "https://cdn.ckeditor.com/ckeditor5/43.3.0/ckeditor5.css"
const STYLESHEET_LINK_ID: string = "ckeditor5Stylesheet"

/**
 * This hook in needed bc "ckeditor5/ckeditor5.css" isn't loaded on non-dev mode, so that cdn stylesheet is used
 * @return void
 */
function useHook(): void {
    useLayoutEffect((): TEmptyCallback => {
        if (document.getElementById(STYLESHEET_LINK_ID)) return emptyCallback

        const link: HTMLLinkElement = document.createElement("link")
        link.rel = "stylesheet"
        link.href = STYLESHEET_LINK_HREF
        link.id = STYLESHEET_LINK_ID

        document.head.appendChild(link)

        return (): void => (document.head.removeChild(link), void 0)
    }, [])
}

export { useHook as useCkRendererStylesheetLink }
