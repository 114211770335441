import "ckeditor5/ckeditor5.css"

import { type NamedExoticComponent, type ReactElement, Suspense, createElement, memo, useMemo } from "react"

import classNames from "classnames"

import { isObject, isString } from "$/utils/gates"

import { type TGetValueFromTranslatedObjectFunction, useGetValueFromTranslatedObject } from "@/hooks"
import { useCkRendererStylesheetLink } from "@/shared/rich-text-renderer/hooks"
import { emptyCallback } from "@/shared/types/functions"

import { RichTextRendererFallbackSpinner as FallbackSpinner } from "./components"
import { ERichTextRendererMode, type TRichTextRendererProps } from "./index"
import { richTextRenderers } from "./renderers"
import { type TRichRendererUtils, richTextRendererUtils } from "./rich-text-renderer-utils"

import "./rich-text-renderer-styles.scss"

const {
    // propsChecker,
    isStringHTML,
    isStringMarkdown,
    isStringPlainText
}: TRichRendererUtils = richTextRendererUtils

const classNamesMap: { [K in ERichTextRendererMode]: string } = {
    [ERichTextRendererMode.Edit]: "edit-mode",
    [ERichTextRendererMode.View]: "view-mode"
}

/**
 * Component for rendering custom created content on admin side.
 * @param content string content to render. Either HTML string or Markdown string or plain string
 * @param mode based on mode, toolbar and editing is active
 * @param onChange 'Edit' mode only! used for content state management
 * @return ReactElement
 */
const Component: NamedExoticComponent<TRichTextRendererProps> = memo(
    ({
        content,
        mode = ERichTextRendererMode.Edit,
        onChange = emptyCallback
    }: TRichTextRendererProps): ReactElement => {
        const getValueFromTranslatedObject: TGetValueFromTranslatedObjectFunction = useGetValueFromTranslatedObject()

        const displayContent: string = useMemo(
            (): string =>
                isString(content) ? content : isObject(content) ? getValueFromTranslatedObject(content) : String(),
            [content, getValueFromTranslatedObject]
        )

        function renderContent(): ReactElement {
            let element: ReactElement = null

            if (mode === ERichTextRendererMode.Edit || isStringHTML(displayContent)) {
                element = createElement(richTextRenderers.HTMLRenderer, { content: displayContent, onChange, mode })
                return element
            }
            if (isStringMarkdown(displayContent)) {
                element = createElement(richTextRenderers.MarkdownRenderer, { content: displayContent })
            }
            if (isStringPlainText(displayContent)) {
                element = createElement(richTextRenderers.BasicRenderer, { content: displayContent })
            }

            return element
        }

        useCkRendererStylesheetLink()

        return (
            <Suspense fallback={<FallbackSpinner className="my-3" />}>
                <div
                    className={classNames("rich-text-renderer", {
                        [classNamesMap[mode]]: mode
                    })}
                >
                    {renderContent()}
                </div>
            </Suspense>
        )
    }
    // ,
    // propsChecker // TODO: WISER-3804 adjust to not to handle string length, handle deep object comparison instead
)

Component.displayName = "RickTextRenderer"

export { Component as RichTextRenderer }
