import { type Dispatch, type FC, type ReactElement, type SetStateAction, useCallback, useId, useState } from "react"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import classNames from "classnames"

import { type TEmptyCallback, emptyCallback } from "@/shared/types/functions"

import {
    ERichTextRendererFallbackSpinnerVariant as EFallbackSpinnerVariant,
    RichTextRendererFallbackSpinner as FallbackSpinner
} from "../components"
import { useCkRendererStylesheetLink } from "../hooks"
import { ClassicEditor, ckEditorConfig } from "../rich-text-renderer-config"
import { ERichTextRendererMode } from "../rich-text-renderer-types"

type TProps = { content: string; mode?: ERichTextRendererMode; onChange(content: string): void }

/**
 * Only for RichTextRenderer. Potentially can be removed once there are no issues with renderers on prod
 * @param content string content to render
 * @param mode based on mode, toolbar and editing is active
 * @param onChange 'Edit' mode only! used for content state management
 * @return ReactElement of Editor or Spinner if editor is not loaded
 */
const HTMLRenderer: FC<TProps> = ({ content, mode, onChange }: TProps): ReactElement => {
    const editorId: string = useId()

    const [isLayoutReady, setIsLayoutReady]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false)

    const handleReady: TEmptyCallback = useCallback((): void => setIsLayoutReady(true), [])

    const handleChange = useCallback(
        (_event, editor): void => {
            if (mode === ERichTextRendererMode.Edit) {
                const data: string = editor.getData()
                onChange(data)
            }
        },
        [mode, onChange]
    )

    useCkRendererStylesheetLink()

    return (
        <>
            {!isLayoutReady && (
                <FallbackSpinner
                    className="my-2"
                    variant={
                        mode === ERichTextRendererMode.View
                            ? EFallbackSpinnerVariant.Default
                            : EFallbackSpinnerVariant.Admin
                    }
                />
            )}
            <div className={classNames({ "d-none": !isLayoutReady })}>
                <CKEditor
                    id={editorId}
                    disabled={mode === ERichTextRendererMode.View}
                    disableWatchdog={mode === ERichTextRendererMode.View}
                    editor={ClassicEditor}
                    config={ckEditorConfig as Required<object>}
                    data={content}
                    onReady={handleReady}
                    onChange={handleChange}
                    onError={emptyCallback}
                    onBlur={emptyCallback}
                    onFocus={emptyCallback}
                />
            </div>
        </>
    )
}

export { HTMLRenderer }
