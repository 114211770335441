import {
    type Context,
    type Dispatch,
    type FC,
    type MutableRefObject,
    type ReactElement,
    type ReactNode,
    type SetStateAction,
    createContext,
    useCallback,
    useContext,
    useMemo,
    useRef,
    useState
} from "react"

import { QueryClient, useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { type NavigateFunction, useNavigate } from "react-router-dom"

import { HTTPStatus } from "$/constants"
import { isEmptyString } from "$/utils/gates"
import { setUserSentry } from "$/utils/set-user-sentry"
import { setUserDataDog } from "$/utils/setuser-datadog"
import {
    getCurrentAccessToken,
    getCurrentRefreshToken,
    setCurrentAccessToken,
    setCurrentRefreshToken
} from "$/utils/tokens"

import { adaptUser } from "@/adapters"
import { type TLogoutMutation, useLogoutMutation } from "@/api/mutations/use-logout-mutation"
import { type TMeQuery, useMeQuery } from "@/api/use-me-query"
import { useSocialCompleteQuery } from "@/api/use-social-complete-query"
import { AuthUrls, ServerStateKeys } from "@/constants"
import type { IJwtTokenPairModel } from "@/models/jwt"
import User from "@/models/user"
import {
    ELocalStorageServiceItemKey,
    ESessionStorageServiceItemKey,
    EStorageServiceType,
    StorageService
} from "@/services"
import { type TEmptyAsyncCallback, emptyAsyncCallback, emptyCallback } from "@/shared/types/functions"

import { type IStoreContext, useStoreContext } from "./GlobalStore"

type TProps = { children: ReactNode }

type TAuthContextType = {
    user: User
    isLoading: boolean
}
type TAuthContextActionType = {
    setUser(usr: User): void
    logout({
        onComplete,
        keepLocalStorageAuthTokens
    }?: {
        onComplete?: TEmptyAsyncCallback
        keepLocalStorageAuthTokens?: boolean
    }): Promise<void>
    refetchUser: TEmptyAsyncCallback
}

const AuthContext: Context<TAuthContextType> = createContext<TAuthContextType>(null)

const AuthContextActions: Context<TAuthContextActionType> = createContext<TAuthContextActionType>(null)

const localStorageService: StorageService = new StorageService(EStorageServiceType.Local)
const sessionStorageService: StorageService = new StorageService(EStorageServiceType.Session)

const AuthContextProvider: FC<TProps> = ({ children }: TProps): ReactElement => {
    const [user, _setUser]: [User, Dispatch<SetStateAction<User>>] = useState<User>(null)
    const { resetState }: IStoreContext = useStoreContext()
    const queryClient: QueryClient = useQueryClient()

    const jwtAuthUrl: MutableRefObject<string> = useRef<string>(JSAPP_CONF.jwt_pair_url ?? String())

    useSocialCompleteQuery({
        enabled: !isEmptyString(jwtAuthUrl.current),
        onSuccess: (data: IJwtTokenPairModel): void => (
            setCurrentAccessToken(data.access),
            setCurrentRefreshToken(data.refresh),
            localStorageService.setItem<boolean>(ELocalStorageServiceItemKey.SocialComplete, true),
            localStorageService.removeItem(ELocalStorageServiceItemKey.MainUserLoggedOut),
            window.location.reload()
        )
    })

    const handleUserSet: (data: User) => void = useCallback(
        (data: User): void => (
            sessionStorageService.removeItem(ESessionStorageServiceItemKey.UserLogout),
            setCurrentAccessToken(data.jwtPair.access),
            setCurrentRefreshToken(data.jwtPair.refresh),
            _setUser(adaptUser(data))
        ),
        []
    )

    const {
        isFetching: isMeDataFetching,
        isRefetching: isMeDataRefetching,
        refetch: handleMeDataRefetch
    }: TMeQuery = useMeQuery({
        enabled: Boolean(!user && !isEmptyString(getCurrentAccessToken())),
        onSuccess: (data: User): void => (
            setUserSentry(data),
            setUserDataDog(data),
            queryClient.setQueryData([ServerStateKeys.User], (): User => data),
            sessionStorageService.removeItem(ESessionStorageServiceItemKey.UserLogout),
            localStorageService.removeItem(ELocalStorageServiceItemKey.MainUserLoggedOut),
            setCurrentAccessToken(data.jwtPair.access),
            setCurrentRefreshToken(data.jwtPair.refresh),
            _setUser(adaptUser(data))
        ),
        onError: (error: AxiosError): void =>
            error?.response?.status === HTTPStatus.UNAUTHORIZED ? _setUser(null) : emptyCallback()
    })

    const handleUserRefetch: TEmptyAsyncCallback = useCallback(
        async (): Promise<void> => (await handleMeDataRefetch(), void 0),
        [handleMeDataRefetch]
    )

    const handleLogoutMutation: TLogoutMutation = useLogoutMutation()

    const navigate: NavigateFunction = useNavigate()

    const handleLogout: (params: { onComplete?: TEmptyAsyncCallback }) => Promise<void> = useCallback(
        async ({
            onComplete = emptyAsyncCallback,
            keepLocalStorageAuthTokens = false
        }: { onComplete?: TEmptyAsyncCallback; keepLocalStorageAuthTokens?: boolean } = {}): Promise<void> => (
            await handleLogoutMutation.mutateAsync(
                { refresh: getCurrentRefreshToken() },
                {
                    onSuccess: (): void => (setUserSentry(null), _setUser(null), resetState(), queryClient.clear()),
                    onError: (error: AxiosError): void =>
                        error?.response?.status === HTTPStatus.UNAUTHORIZED && navigate(AuthUrls.LOGIN),
                    onSettled: async (): Promise<void> => (
                        await queryClient.cancelQueries({ type: "all" }, { silent: true }),
                        await queryClient.invalidateQueries({ type: "all" }),
                        await queryClient.resetQueries({ type: "all" }),
                        keepLocalStorageAuthTokens ? void 0 : localStorageService.clearStorage(),
                        sessionStorageService.clearStorage(),
                        sessionStorageService.setItem<boolean>(ESessionStorageServiceItemKey.UserLogout, true),
                        navigate(AuthUrls.LOGIN),
                        onComplete?.()
                    )
                }
            ),
            void 0
        ),
        [handleLogoutMutation, resetState, queryClient, navigate]
    )

    const values: TAuthContextType = useMemo(
        (): TAuthContextType => ({
            user,
            isLoading: isMeDataFetching || isMeDataRefetching
        }),
        [user, isMeDataFetching, isMeDataRefetching]
    )

    const actions: TAuthContextActionType = useMemo(
        (): TAuthContextActionType => ({
            logout: handleLogout,
            setUser: handleUserSet,
            refetchUser: handleUserRefetch
        }),
        [handleLogout, handleUserRefetch, handleUserSet]
    )

    return (
        <AuthContext.Provider value={values}>
            <AuthContextActions.Provider value={actions}>{children}</AuthContextActions.Provider>
        </AuthContext.Provider>
    )
}

interface IAuthContext extends TAuthContextType, TAuthContextActionType {}

/**
   Unfortunately, should be used both in main-app and Manager apps. Thus, throwOnError should be passed explicitly bc Manager isn't wrapped in AuthContext and can't retrieve user
 * @param throwError
 */
function useAuthContext(
    { throwError }: { throwError?: boolean } = { throwError: true }
): ReturnType<() => IAuthContext> {
    const auth: TAuthContextType = useContext(AuthContext)
    const actions: TAuthContextActionType = useContext(AuthContextActions)

    if ((!auth || !actions) && throwError) {
        throw new Error("useAuthContext can only be used inside AuthProvider")
    }

    return {
        ...auth,
        ...actions
    }
}

export { AuthContext, useAuthContext, type IAuthContext, AuthContextProvider }
