import type { TUserModel } from "@/adapters"
import { Role } from "@/constants"

const isParticipant: (user: TUserModel) => boolean = (user: TUserModel): boolean => user?.role === Role.Participant

const isCoach: (user: TUserModel) => boolean = (user: TUserModel): boolean => user?.role === Role.Coach

const isProgramManager: (user: TUserModel) => boolean = (user: TUserModel): boolean =>
    user?.role === Role.ProgramManager

export { isParticipant, isCoach, isProgramManager }
