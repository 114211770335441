import type { FC, ReactElement } from "react"

import buildCssClassName from "classnames"

import { Spinner } from "@/shared/spinner"

enum EVariant {
    Default = "default",
    Admin = "admin"
}

type TProps = { className?: string; variant?: EVariant }

const configMap: { [K in EVariant]: string } = {
    [EVariant.Default]: "",
    [EVariant.Admin]: "admin"
}

const Component: FC<TProps> = ({ className = String(), variant = EVariant.Default }: TProps): ReactElement => (
    <Spinner
        className={buildCssClassName("rich-text-renderer__spinner", className, { [configMap[variant]]: variant })}
    />
)

export { Component as RichTextRendererFallbackSpinner, EVariant as ERichTextRendererFallbackSpinnerVariant }
