import { startTransition, useCallback } from "react"

import { type NavigateFunction, useNavigate } from "react-router-dom"

import { isEmptyString } from "$/utils/gates"
import { setCurrentAccessToken, setCurrentRefreshToken } from "$/utils/tokens"

import { INDEX_URL } from "@/constants"
import type { IJwtTokenPairModel } from "@/models/jwt"
import {
    ELocalStorageServiceItemKey,
    ESessionStorageServiceItemKey,
    EStorageServiceType,
    StorageService,
    type TStoredSubUser
} from "@/services"

const localStorageService: StorageService = new StorageService(EStorageServiceType.Local)
const sessionStorageService: StorageService = new StorageService(EStorageServiceType.Session)

type TUseSecondaryAuth = {
    reloadUser: ({ userId }: { userId: string }) => void
}

function useSecondaryAuth(): ReturnType<() => TUseSecondaryAuth> {
    const navigate: NavigateFunction = useNavigate()

    const reloadUser: ({ userId }: { userId: string }) => void = useCallback(
        ({ userId }: { userId: string }): void => {
            const { access: accessToken, refresh: refreshToken }: IJwtTokenPairModel = localStorageService
                .getItem<TStoredSubUser[] | null>(ELocalStorageServiceItemKey.SubUserList)
                ?.find((u: TStoredSubUser): boolean => u?.id === Number(userId))?.jwtPair ?? {
                access: String(),
                refresh: String()
            }

            if (!isEmptyString(userId) && !isEmptyString(accessToken) && !isEmptyString(refreshToken)) {
                sessionStorageService.setItem<boolean>(
                    ESessionStorageServiceItemKey.FakeParticipantsFeatureEnabled,
                    true
                )

                setCurrentAccessToken(accessToken)
                setCurrentRefreshToken(refreshToken)

                startTransition((): void => window.location.replace(INDEX_URL)) // can't navigate properly with useNavigate()
            } else {
                startTransition((): void => navigate(INDEX_URL, { replace: true }))
            }
        },
        [navigate]
    )

    return { reloadUser }
}

export { useSecondaryAuth, type TUseSecondaryAuth }
