import type { FC, ReactElement } from "react"

import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"

type TProps = { content: string }

/**
 * Only for RichTextRenderer. Component for rendering custom created content on admin side.
 * @param content string content to render. For Markdown strings!
 * @return ReactMarkdown output
 */
const MarkdownRenderer: FC<TProps> = ({ content }: TProps): ReactElement => (
    <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
)

export { MarkdownRenderer }
