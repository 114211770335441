// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from "@sentry/browser"

import User from "@/models/user"

export const setUserSentry = (user: User | null): void => {
    const userData = user
        ? {
              id: `${user.id}`,
              name: `${user.firstName} ${user.lastName}`,
              email: user.email,
              organization: user.organization,
              version: JSAPP_CONF?.static ?? ""
          }
        : null

    Sentry.setUser(userData)
}
